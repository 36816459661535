/* El overlay cubre toda la pantalla */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  z-index: 1000;
}

/* Contenedor principal del menú */
.menuManagContainer {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  max-height: 100vh;
  text-align: center;
  position: relative;
}

/* Botón para cerrar la ventana */
.closeBtn {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.h2 {
  font-size: 2em;
  font-weight: bold;
  font-family: "Inter", sans-serif;
}

/* Lista de opciones */
.menuList {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
}

.menuList li {
  padding: 10px 0;
  cursor: pointer;
  font-size: 18px;
  transition: 0.3s;
}

.menuList li:hover {
  transform: scale(1.08);
  color: #f67924;
}
