/* ChangePasswordForm.module.css */

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  font-family: Inter, sans-serif;
}

.title {
  font-size: 2.5rem;
  color: #476930;
  margin-bottom: 2rem;
}

.form {
  width: 100%;
  max-width: 400px;
}

.formGroup {
  margin-bottom: 1.5rem;
}

.label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #476930;
}

.input {
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.inputError {
  border-color: #dc3545;
}

.input:focus {
  outline: none;
  border-color: #476930;
  box-shadow: 0 0 0 0.2rem #476930;
}

.errorMessage {
  color: #dc3545;
  font-size: 0.9em;
  font-weight: 600;
}

.button {
  width: 100%;
  padding: 0.75rem 1rem;
  margin-top: 1em;
  font-size: 1rem;
  background-color: #f67924;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.button:hover:enabled {
  background-color: #f67924;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .mainContent {
    padding: 1rem;
  }

  .title {
    font-size: 2rem;
  }

  .input,
  .button {
    font-size: 0.9rem;
  }
}
