.sidebar {
  background-color: #9a7b55;
  width: 65px;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  gap: 20px;
}

.infoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar::before {
  content: "";
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  border-width: 15px;
  border-style: solid;
  border-color: transparent transparent transparent #9a7b55;
  z-index: 400;
}

.iconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.iconContainer:hover {
  cursor: pointer;
}

.icon {
  width: 40px;
  height: 40px;
}

.iconText {
  font-size: 0.75em;
  color: black;
  font-family: "Inter", sans-serif;
  font-weight: 700;
}

.separator {
  height: 1px;
  width: 40px;
  border: none;
  border-top: 1px solid black;
}

.dropdownMenu {
  position: absolute;
  top: 0px;
  left: 65px;
  background-color: #d8eece;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  padding: 10px;
  z-index: 401;
  width: 350px;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}

.dropdownMenu::-webkit-scrollbar {
  width: 8px;
}

.dropdownMenu::-webkit-scrollbar-track {
  background: #d8eece;
  border-radius: 10px;
}

.dropdownMenu::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #d8eece;
}

.dropdownMenu::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.favoriteList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.menuItemHeader {
  margin-left: -10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.placeTitle {
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  color: black;
}

.ratingContainer {
  display: flex;
  align-items: center;
}

.ratingIcon {
  width: 20px;
  height: auto;
  margin-right: 5px;
}

.ratingValue {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: black;
}

.menuItem {
  padding: 10px;
  border-bottom: 1px solid black;
  background-color: #d8eece;
}

.menuItem:hover {
  cursor: pointer;
  background-color: #c5e4c3;
}

.menuItem2 {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menuItem h3 {
  margin: 0;
  font-size: 1.2rem;
}

.menuItem p {
  font-size: 0.9rem;
  color: #283d31;
  font-family: "Bitter", sans-serif;
  font-weight: 500;
}

.row {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  word-wrap: break-word;
  word-break: break-all;
  padding-right: 15px;
}

.row img {
  margin-right: 8px;
}

.menuItem2 p,
.menuItem2 a {
  font-size: 0.9rem;
  color: #283d31;
  font-family: "Bitter", sans-serif;
  font-style: italic;
  font-weight: bold;
  margin: 0;
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.icon2 {
  width: 16px;
  height: auto;
}

.iconHeart {
  width: 24px;
  height: auto;
}

.heartContainer {
  margin-left: auto;
}

/* SideBar */
.selectedItem {
  background-color: #c5e4c3;
  border-left: 4px solid #476930;
}

.dropdownMenu {
  overflow-y: auto;
  max-height: 80vh;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.showMore {
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  gap: 5px;
}

.showMoreText {
  font-size: 1em;
}
