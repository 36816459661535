.footer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  background-color: #476930;
  height: 8em;
  padding-top: 1em;
  overflow: hidden;
}

.main-content {
  flex: 1;
}

.logoCopy {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2em;
  width: 20em;
}

.logoCopy img {
  width: 100%;
  height: 100%;
  margin: auto;
}

.copyRightText {
  margin-left: 3em;
  margin-top: 1em;
  color: #171f20;
  font-size: 0.8em;
}

.copyPharagraph {
  margin-top: 1.5em;
}

.listHelp {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-left: auto;
  text-align: right;
  padding-right: 3em;
  white-space: nowrap;
  padding-left: 3em;
}

.listHelp ul {
  list-style-type: none;
}

.listHelp li {
  display: inline;
  margin-right: 1em;
}

.listHelp {
  text-decoration: none;
  color: #171f20;
  font-size: 0.8em;
  pointer: cursor;
}

.listHelp a {
  transition: 0.3s;
}

.listHelp a:hover {
  cursor: pointer;
  color: #f2f2f2;
}

.firstListFooter {
  margin-top: 10%;
}

.menuButtonFooter {
  display: none;
  margin-left: auto;
}

.menuButtonFooter i {
  color: #171f20;
  font-size: 3em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1em;
  margin-top: 0.5em;
}

/*Menu when devices*/
.listHelpMenu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1;
  opacity: 0.85;
  overflow-x: hidden;
  transition: 0.5s;
}

.closeButtonMenuFooter {
  display: flex;
  justify-content: flex-end;
  color: white;
  font-size: 2em;
  margin-right: 1em;
  margin-top: 1em;
}

/* Styles for bigger screens */
@media (min-width: 601px) {
  .menuButtonFooter {
    display: none;
  }

  .listHelp {
    display: flex;
  }
}

/* Styles for small screens */
@media (max-width: 950px) {
  .copyRightText {
    display: none;
  }

  .logoCopy img {
    width: 80%;
    height: 80%;
  }
}

/* Esconder elementos y mostrar el botón de menú en pantallas pequeñas */
@media (max-width: 600px) {
  .listHelp {
    display: none;
  }

  .copyRightText {
    display: none;
  }

  .menuButtonFooter {
    display: block;
  }

  .listHelpMenu.open {
    display: block;
  }
}
