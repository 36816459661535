/* RecoveryPassword.module.css */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Inter, sans-serif;
}

.title {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
}

.instructions {
  font-size: 1em;
  color: #666;
  text-align: center;
  max-width: 400px;
  margin-bottom: 2rem;
}

.form {
  width: 100%;
  max-width: 400px;
}

.input {
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button {
  width: 100%;
  padding: 0.75rem 1rem;
  margin-top: 1em;
  font-size: 1rem;
  background-color: #f67924;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #218838;
}

.inputError {
  border-color: #dc3545;
}

.errorMessage {
  color: #dc3545;
  font-size: 0.9em;
  font-weight: 600;
}
