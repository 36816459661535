.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}

.loader {
  width: 128px;
  height: 128px;
  position: relative;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.loader:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 80px;
  height: 80px;
  transform: rotate(45deg) translate(30%, 40%);
  background: rgb(112, 177, 112);
  box-shadow: 64px -68px 0 10px darkgreen;
  animation: slide 2s infinite ease-in-out alternate;
}

.loader:after {
  content: "";
  position: absolute;
  left: 20px;
  top: 20px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: orange;
  transform: rotate(0deg);
  transform-origin: 70px 290px;
  animation: rotate 2s infinite ease-in-out;
}

@keyframes slide {
  0%,
  100% {
    bottom: -70px;
  }

  25%,
  75% {
    bottom: -4px;
  }

  20%,
  80% {
    bottom: 4px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(-15deg);
  }

  25%,
  75% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(25deg);
  }
}
