.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  overflow-x: auto;
}

.mainBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  width: 100%;
  border-bottom: 1px solid #f67924;
}

.button {
  background-color: #4caf50;
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 1em;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 15px;
  transition: 0.4s;
}

.adjunto {
  background-color: #f67924;
}

.download {
  background-color: #476930;
}

.button:hover {
  background-color: #ff3f3f;
}

.bodyTitle {
  font-family: Bitter, serif;
  font-weight: 700;
  font-size: 2em;
  color: #476930;
}

.statsInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 1em;
  color: #476930;
  gap: 3em;
}

.stats {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  border: 1px solid #f67924;
  padding: 2em;
  border-radius: 15px;
  min-width: 350px;
}

.titleStats {
  font-family: Inter, serif;
  font-weight: 800;
  font-size: 1.5em;
  color: #476930;
}

.minStats {
  display: flex;
  gap: 20px;
  font-style: italic;
  width: 100%;
  justify-content: space-between;
}

.numberStats {
  font-family: Inter, sans-serif;
  font-weight: 700;
  color: #476930;
}

.graph {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table {
  width: 100%;
  padding: 3em;
  overflow-x: auto;
}

.myTable th,
.myTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  min-width: 50px;
  width: auto;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
}

.myTable th {
  background-color: #f2f2f2;
  text-align: left;
}

.myTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.myTable tr:hover {
  background-color: #ddd;
}

.no-break {
  page-break-inside: avoid;
  break-inside: avoid;
}

.page-break {
  page-break-after: always;
  break-after: page;
}

.pdfContent {
  max-width: 300mm;
  margin: 0 auto;
}

.mainBody {
  font-size: 12px;
  line-height: 1.4;
}

.tableContainer {
  overflow-x: auto;
  max-width: 100%;
}

@media (max-width: 768px) {
  .titleContainer {
    flex-direction: column;
    gap: 20px;
  }

  .mainBody {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 20px;
  }

  .bodyTitle {
    font-size: 1.5em;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .statsInfo {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }
}
