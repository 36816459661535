.logo img {
  height: 3em;
  pointer-events: none;
}
.logo:hover {
  cursor: pointer;
}

.header {
  width: 100%;
  display: flex;
  background-color: white;
  padding: 0.5em;
  padding-top: 1em;
}

.actions {
  display: flex;
  margin-left: auto;
  gap: 1em;
  padding-left: 0.8em;
}

.btnPlaces,
.btnMap,
.btnLogin,
.btnMenu {
  transition: 0.6s;
}
.btnPlaces:hover,
.btnMap:hover,
.btnLogin:hover,
.btnMenu:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.actions .imgg,
.actions p {
  height: 2em;
  margin: auto;
  word-wrap: break-word;
}

.btnMenu {
  margin-right: 0.5em;
  margin-top: 0.6em;
  margin-left: 0.5em;
}

@media (max-width: 600px) {
  .actions {
    gap: 0.5em;
    padding-left: 0.5em;
    font-size: 0.6em;
  }
}
